<template>
    <div class="about">
        <topBtn ref="topBtn"></topBtn>
        <heads></heads>
        <div id="zzz"></div>
        <div class="new_nav">
            <div class="all_box">
                <div class="new_nav_box" id="new_nav">
                    <ul>
                        <li v-for="(item, index) in books" :key="index" @click="currentIndex=index" :class="currentIndex===index?'active':''">{{ item.tabName }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-for="(item, index) in books" :key="index">
            <div v-show="currentIndex === index" :label="item.tabName" :name="'book' + index">
                <div :id="'bookContainer' + index" class="bookContainer"></div>

                <!-- <div style="padding-bottom: 20px">
                <el-button @click="downloadUrl(item.url,item.name)" type="primary">下载</el-button>
                </div> -->
            </div>
        </div>


        <foots @navChange="navChange"></foots>
    </div>
</template>
<script>
import topBtn from '@/components/topBtn/index.vue'
import heads from '@/components/head/index.vue'
import foots from '@/components/foot/index.vue'

// import docx from "docx-preview"
import { fetchBlob, downloadUrl } from "@/utils/index"
export default {
    name: 'about',
    data () {
        return {
            currentIndex: 0,
            footType: '',

            books: [{
                url: '/员工手册/002-01员工手册（通用版）20240731.docx',
                name: '002-01员工手册（通用版）20240731.docx',
                tabName: "员工手册通用版",
            }, {
                url: '/员工手册/002-02物业行业员工手册（正式版V1.0）20240731.docx',
                name: '002-02物业行业员工手册（正式版V1.0）20240731.docx',
                tabName: "物业行业员工手册",
            }, {
                url: '/员工手册/002-03餐饮行业员工手册（正式版V1.0）20240731.docx',
                name: '002-03餐饮行业员工手册（正式版V1.0）20240731.docx',
                tabName: "餐饮行业员工手册",
            }, {
                url: '/员工手册/002-04物流行业员工手册-（正式版V1.0）20240731.docx',
                name: '002-04物流行业员工手册-（正式版V1.0）20240731.docx',
                tabName: "物流行业员工手册",
            },]
        }
    },
    mounted () {
        this.$refs.topBtn.data = this.$refs.zzz
        if (this.$route.params.type) {
            this.footType = this.$route.params.type
        }
        this.$nextTick(() => {
            this.showDocx(0)

            requestIdleCallback(() => {
                this.showDocx(1)
                this.showDocx(2)
                this.showDocx(3)
            },)
        })
    },
    components: {
        heads,
        foots,
        topBtn
    },
    watch: {
        currentIndex () {
            this.showDocx(this.currentIndex)
        }
    },
    methods: {
        downloadUrl,
        showDocx (tabIndex) {
            const docData = this.books[tabIndex]
            if (!docData.success) {
                

                fetchBlob(docData.url).then(async ([blobData, mimeType]) => {
                    await docx.renderAsync(new Blob([blobData], { type: mimeType }), document.getElementById("bookContainer" + tabIndex))
                    docData.success = true
                })
            }
        },

        navChange (type) {
            if (type == 0) {
                //关于我们
                document.getElementById("about").scrollIntoView();
            }
            if (type == 1) {
                //了解邦芒
                document.getElementById("about").scrollIntoView();
            }
            if (type == 2) {
                //发展大事记
                document.getElementById("sm").scrollIntoView();
            }
            if (type == 3) {
                //荣誉墙
                document.getElementById("honor").scrollIntoView();
            }
            if (type == 4) {
                //企业文化
                document.getElementById("qywh").scrollIntoView();
            }
        },

    },
}
</script>
<style>
.bookContainer {
    min-height: 100vh;

}
.docx-wrapper {
    background: transparent !important;
}

.docx {
    box-shadow: none !important;
    border: 1px solid #ddd;
}

p.docx_a5 {
    background: transparent !important;
}

p.docx_a5 * {
    background: transparent !important;

}
</style>
<style lang="scss" scoped>
.new_nav {
    background: #ededed;
}

.new_nav_box {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            margin-left: 158px;
            cursor: pointer;
            padding: 30px 0;


            &.active {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0px;
                    width: 100%;
                    height: 4px;
                    background: #c81422;
                }
            }


        }

        li:first-child {
            margin-left: 0;
        }
    }

}
</style>