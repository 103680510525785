<template>
  <div class="home">
    <heads></heads>
    <!-- banner -->
    <div class="swiper-container " id="homeBannerSwiper">
      <div class="swiper-wrapper">
<!--        <div class="swiper-slide" v-for="(item,index) of swiperList">-->
<!--          <span>{{item.h3}}</span>-->
<!--        </div>-->
        <div class="swiper-slide banner relative">
          <img src="@/assets/img/banner.png">
        </div>
        <div class="swiper-slide banner relative">
          <img src="@/assets/img/banner0.jpg">

          <div class="absolute top-20 left-1/2 -translate-x-1/2">
            <div class="text-[80px] text-white whitespace-nowrap font-bold">专业人力赋能 共筑出海腾飞</div>
            <div class="text-[50px] text-white whitespace-nowrap mt-5">创新香港国际人才嘉年华大型招聘会</div>

            <div class="mt-20 flex justify-center">
              <a href="https://www.wjx.cn/vm/rXVKBR2.aspx" class="border-solid border-4 border-white bg-[#339B4F] text-white w-[320px] h-[86px] rounded-full  text-[28px] font-bold flex justify-center items-center hover:-translate-y-3 hover:shadow-3xl">企业参展报名入口</a>
              <a href="https://www.wjx.cn/vm/QfCellW.aspx" class="ml-10 border-solid border-4 border-white bg-[#7abb23] text-white w-[320px] h-[86px] rounded-full  text-[28px] font-bold flex justify-center items-center hover:-translate-y-3 hover:shadow-3xl">香港业务咨询入口</a>

            </div>
          </div>

        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>

    <!-- about -->
    <div class="about">
      <div class="all_box">
        <div class="aout_top">
          <div class="about_top_text">
            <div class="about_title_box">
              <h3>邦芒简介</h3>
              <h4 class="flex items-end mt-5 ml-2">about us</h4>
            </div>
            <div class="about_text-wz">
              <p v-for="item in $config.intro" :key="item">{{ item }}</p>

            </div>
          </div>
          <div class="about_top_video">
            <!-- <img src="@/assets/img/about_video.jpg"> -->
            <video
              src="https://1500018560.vod2.myqcloud.com/cba17923vodsh1500018560/fdb4002c243791580028603760/II3yoVTy5J4A.mp4"
              controls="controls" style="margin-top:20px"></video>
            <!-- <video src="@/assets/video/home_video.mp4" controls="controls" style="margin-top:20px"></video> -->
          </div>
        </div>
        <div class="about_botoom">
          <div class="about_bottom_list">
            <div class="about_bottom_item">
              <h4>深耕行业</h4>
              <div class="big_text">{{ $config.bmYears }}<span>年</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
            <div class="about_bottom_item">
              <h4>直营公司</h4>
              <div class="big_text">228<span>家</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
            <div class="about_bottom_item">
              <h4>覆盖城市</h4>
              <div class="big_text">400<span>+</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
            <div class="about_bottom_item">
              <h4>专业团队</h4>
              <div class="big_text">2600<span>+</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
            <div class="about_bottom_item">
              <h4>服务客户</h4>
              <div class="big_text">30000<span>+</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
            <div class="about_bottom_item">
              <h4>雇员数量</h4>
              <div class="big_text">1000000<span>+</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- serve -->
    <div class="serve">
      <div class="all_box">
        <div class="serve_title">
          <h3>邦芒服务</h3>
          <p>OUR SERVICES</p>
          <div class="henxian"></div>
        </div>

        <div class="serve_body">
          <el-tabs v-model="activeName">
            <el-tab-pane v-for="(item,index) in $config.bmServices" :key="item.title" :label="item.title" :name="item.title">
              <span slot="label" class="serve_tab">
                <div class="serve_top">
                  <div class="serve_top_picbox"><img :src="item.icon" alt=""></div>
                </div>
                <p>{{item.title}}</p>
              </span>
              <div class="serve_box">
                <div class="serve_img">
                  <img :src="require(`@/assets/img/serve_pic0${index+1}.png`)">
                </div>
                <div class="serve_text">
                  <p>{{item.desp}}</p>
                  <div class="serve_more" @click="jumpNav('business', item.title)">
                    <p>查看详情</p>
                    <img src="@/assets/img/home_more_off.png" class="img_off">
                    <img src="@/assets/img/home_more_on.png" class="img_on">
                  </div>
                </div>
              </div>
            </el-tab-pane>


          </el-tabs>
        </div>
      </div>
    </div>

    <!-- 邦芒优势 -->
    <div class="advantage">
      <div class="all_box">
        <div class="ys_title">
          <h3>邦芒优势</h3>
          <p>{{ $config.bmYears }}年专业人力资源服务提供商</p>
          <img src="@/assets/img/ys_title_img.png">
        </div>
        <div class="ys_list">
          <div class="ys_item">
            <div class="ys_title_sm">优势一</div>
            <div class="ys_img">
              <img src="@/assets/img/ys01.png" class="ys_img_one">
              <img src="@/assets/img/ys_on01.png" class="ys_img_two">
            </div>
            <div class="ys_text">
              全国落地 专属定制
            </div>
            <div class="ys_henxian">

            </div>
          </div>
          <div class="ys_item">
            <div class="ys_title_sm">优势二</div>
            <div class="ys_img">
              <img src="@/assets/img/ys02.png" class="ys_img_one">
              <img src="@/assets/img/ys_on02.png" class="ys_img_two">
            </div>
            <div class="ys_text">
              优质产品 弹性应用
            </div>
            <div class="ys_henxian">

            </div>
          </div>
          <div class="ys_item">
            <div class="ys_title_sm">优势三</div>
            <div class="ys_img">
              <img src="@/assets/img/ys03.png" class="ys_img_one">
              <img src="@/assets/img/ys_on03.png" class="ys_img_two">
            </div>
            <div class="ys_text">
              数智技术 个性支持
            </div>
            <div class="ys_henxian">

            </div>
          </div>
          <div class="ys_item">
            <div class="ys_title_sm">优势四</div>
            <div class="ys_img">
              <img src="@/assets/img/ys04.png" class="ys_img_one">
              <img src="@/assets/img/ys_on04.png" class="ys_img_two">
            </div>
            <div class="ys_text">
              专业团队 保驾护航
            </div>
            <div class="ys_henxian">

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 横幅 -->
    <div class="henfu">
      <h3>邦芒人力布局全球 服务网点已达228家</h3>
    </div>

    <!-- 邦芒人力 -->
    <div class="bmrl_box">
      <div class="all_box">
        <div class="bmrl_title">
          <h3>邦芒人力</h3>
          <p>{{ $config.bmYears }}年专业人力资源服务提供商</p>
          <img src="@/assets/img/ys_title_img.png">
        </div>
        <div class="bmrl_body">
          <el-row :gutter="20">
            <el-col :span="12" id="form_title">
              <div class="body_map">
                <img src="/dlt.jpg">
                <div>邦芒总部大楼</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="body_map">
                <img src="/官网更新图集/邦芒科技大厦.jpg">
                <div>邦芒科技大厦</div>
              </div>
              <!-- <div class="body_swiper">
                <el-carousel height="452px">
                  <el-carousel-item v-for="item in swiperList" :key="item">
                    <div class="bmrl_swiper_img">
                      <img :src="item">
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div> -->
            </el-col>
          </el-row>
        </div>

        <div class="body_form">
          <div class="form_title">
            <img src="@/assets/img/contact_add.png">
            <h3>全国网点查询<span>| 联系邦芒人力</span></h3>
          </div>
          <div class="form_body">
            <el-row>
              <el-col :span="12" style="padding: 10px 10px 10px 0;">
                <el-select v-model="dataForm.sCode" placeholder="省份" filterable style="width: 100%;" @change="getQList">
                  <el-option v-for="item in options.sList" :key="item.code" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12" style="padding: 10px 0 10px 10px;">
                <el-select v-model="dataForm.qCode" placeholder="城市" filterable style="width: 100%;">
                  <el-option v-for="item in options.qList" :key="item.code" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </el-col>

              <el-col :span="20" style="padding: 10px 10px 10px 0;">
                <el-input v-model="dataForm.compName" placeholder="请输入内容"></el-input>
              </el-col>

              <el-col :span="4" style="padding: 10px 0 10px 10px;">
                <el-button type="info" style="width: 100%;background: #ab1621;border-radius: 0;border: none;"
                  @click="goToContact">搜索网点</el-button>
              </el-col>

            </el-row>
          </div>
        </div>
      </div>
    </div>




    <!-- 标杆案例 -->

    <div class="bgal">
      <div class="all_box">
        <div class="bgal_title">
          <h3>标杆案例</h3>
          <h4>{{ numberToUpperCase($config.bmYears) }}年不懈努力</h4>
          <h4>邦芒人力已服务30000+企业</h4>
        </div>
        <div class="bgal_list">
          <div class="bgal_item">
            <div class="bgal_item_img">
              <img src="@/assets/img/anli01.png">
            </div>
            <div class="bgal_item_text">
              邦芒多年来与我们保持着良好的合作关系，他们的服务专业、高效，帮助我们解决了当下的用工难题，我们真挚地祝福邦芒未来会更好！
            </div>
            <div class="bgal_item_foot">
              ——居民服务行业某翘楚企业
            </div>
          </div>
          <div class="bgal_item">
            <div class="bgal_item_img">
              <img src="@/assets/img/anli02.png">
            </div>
            <div class="bgal_item_text">
              邦芒自主研发的ERP和HR SaaS系统，帮助我们实现了人力资源管理数字化转型，提升了我们日常的人事工作效率。未来，希望能与邦芒深入交流合作，成就更好的彼此！
            </div>
            <div class="bgal_item_foot">
              ——能源化工行业某500强企业
            </div>
          </div>
          <div class="bgal_item">
            <div class="bgal_item_img">
              <img src="@/assets/img/anli03.png">
            </div>
            <div class="bgal_item_text">
              在合作过程中，我们能深切地感受到整个团队的热情、专业和活力，无论是在业务辅导还是日常交流中，团队都给予了我们极大的帮助和支持，邦芒的未来会更好！
            </div>
            <div class="bgal_item_foot">
              ——餐饮行业某老牌企业
            </div>
          </div>
          <div class="bgal_item">
            <div class="bgal_item_img">
              <img src="@/assets/img/anli04.png">
            </div>
            <div class="bgal_item_text">
              在与邦芒合作的这几年，在政策解读、人力资源专业服务上给了我们很多的帮助和支持，帮我们解决了百来个社保公积金户的托管问题，感谢你们，希望你们未来越来越好！
            </div>
            <div class="bgal_item_foot">
              ——仓储物流行业某头部企业
            </div>
          </div>
        </div>



        <friend></friend>

      </div>
    </div>

    <!-- 荣誉资质 -->
    <div class="honor">
      <div class="all_box">
        <div class="honor_title">
          <h3>荣誉资质</h3>
          <img src="@/assets/img/new_title_img.png">
        </div>
        <honor></honor>
      </div>
    </div>




    <!-- 新闻中心 -->
    <div class="new_box">
      <div class="all_box">
        <div class="new_title">
          <h3>新闻中心</h3>
          <img src="@/assets/img/new_title_img.png">
        </div>
        <div class="new_hot_box">
          <div class="new_hot_item" @click="gotoNewDetail(hyNewFirst, 1)">
            <div class="new_hot_title">
              <p class="new_hot_item_title" v-if="hyNewList.length > 0">{{ hyNewList[0].title }}</p>
              <p class="new_hot_item_time" v-if="hyNewList.length > 0">{{ hyNewList[0].createDate }}</p>
            </div>
            <img v-if="hyNewList.length > 0" :src="hyNewList[0].imageUrl">
          </div>
          <div class="new_hot_item" @click="gotoNewDetail(bmNewFirst, 2)">
            <div class="new_hot_title">
              <p class="new_hot_item_title" v-if="bmNewList.length > 0">{{ bmNewList[0].title }}</p>
              <p class="new_hot_item_time" v-if="bmNewList.length > 0">{{ bmNewList[0].createDate }}</p>
            </div>
            <img v-if="bmNewList.length > 0" :src="bmNewList[0].imageUrl">
          </div>
        </div>
        <div class="new_list_box">
          <div class="newlist_box_left">
            <div class="new_item newItem" v-for="item in hyNewList" @click="gotoNewDetail(item.id, 1)">
              <p class="new_item_text">{{ item.title }}</p>
              <p class="new_item_time">{{ item.createDate }}</p>
            </div>
          </div>
          <div class="newlist_box_right">
            <div class="new_item newItem" v-for="item in bmNewList" @click="gotoNewDetail(item.id, 2)">
              <p class="new_item_text">{{ item.title }}</p>
              <p class="new_item_time">{{ item.createDate }}</p>
            </div>
          </div>


        </div>
      </div>
    </div>

    <foots></foots>

    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Swiper from "swiper"
import honor from '@/components/honor/index.vue'
import heads from '@/components/head/index.vue'
import foots from '@/components/foot/index.vue'
import friend from '@/components/friend/index.vue'


// import netWorkBanner01 from '@/assets/官网更新图集/邦芒科技大厦.jpg'
// import netWorkBanner02 from '@/assets/img/netWorkBanner02.png'

export default {
  name: 'Home',
  data () {
    return {
      hyNewFirst: '',
      bmNewFirst: '',
      activeName: this.$config.bmServices[0].title,
      dataForm: {
        sCode: '',
        qCode: '',
        compName: ''
      },
      hyNewList: [],
      bmNewList: [],
      options: {
        sList: [],
        qList: [],
      },
      swiperList: [
        // netWorkBanner01,
        // netWorkBanner02
      ]
    }
  },
  mounted () {
    //获取省
    this.getSList()
    this.getNewListSc()
    this.getNewListBm()
    //底部路由跳转定位
    if (this.$route.params.type == 1) {
      document.getElementById("form_title").scrollIntoView();
    }
    const galleryThumbs = new Swiper('#homeBannerSwiper', {
      spaceBetween: 0, //在slide之间设置距离（单位px）
      slidesPerView: 1, //设置slider容器能够同时显示的slides数量
      loop: true, //设置为true 则开启loop模式
      // freeMode: true, //默认为false，普通模式：slide滑动时只滑动一格
      loopedSlides: 5, //一般设置大于可视slide个数2个即可
      // watchSlidesVisibility: true, //开启watchSlidesVisibility选项前需要先开启watchSlidesProgress
      // watchSlidesProgress: true, //开启这个参数来计算每个slide的progress(进度、进程)
      autoplay: { // 自动播放配置开始
        delay: 2500, // 自动切换时间间隔，单位ms
        // disableOnInteraction: false, // 用户操作后是否禁止自动切换，默认为true，设置为false则用户操作后仍然继续自动播放
      },
      pagination: {
        el: '.swiper-pagination',
        clickable :true,
        type: 'bullets',
        // renderBullet: function (index, className) {
        //   return '<span class="' + className + '">' + (index + 1) + '</span>';
        // },
// renderBullet: function (index, className) {
//      // switch(index){
//      //   case 0:text='壹';break;
//      //   case 1:text='贰';break;
//      //   case 2:text='叁';break;
//      //   case 3:text='肆';break;
//      //   case 4:text='伍';break;
//      // }
//      return '<span class="' + className + '"></span>';
//    },
 },
});
galleryThumbs.el.onmouseover = function(){
 galleryThumbs.autoplay.stop();
};
galleryThumbs.el.onmouseleave = function(){
 galleryThumbs.autoplay.start();
};
// swiper.on('click',function(e){
//   console.log(e.target);
// });
},
components: {
heads,
foots,
honor,
friend
},
methods: {
numberToUpperCase (num) {
 if(!num) return ""
 var chineseNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
 var chineseUnit = ["", "十", "百", "千", "万", "亿"];

 // 将数字转换为字符串，并将字符串倒序存放
 var numStr = num.toString();
 var numArr = numStr.split("");

 // 定义结果字符串
 var result = "";

 // 遍历每一位数字并转换为对应的大写中文
 for (var i = 0; i < numArr.length; i++) {
   var digit = parseInt(numArr[i]);
   var unit = numArr.length - i - 1;

   if (digit === 0) {
     // 如果当前位数字为 0，则只有单位不为空时才需要添加“零”
     if (unit !== 0) {
       result += chineseNum[digit];
     }
   } else {
     // 当前位数字不为 0，添加大写中文数字和单位
     result += chineseNum[digit] + chineseUnit[unit];
   }
 }
 if (numArr.length == 2 && numArr[0] == 1) {
   result = result.replace(/^[\u4e00-\u9fa5]/g, '');
 }

 return result;
},
jumpNav (item, type) {

 this.$router.push({
   name: item,
   params: {
     type
   }
 });

},

//跳转新闻详情页
gotoNewDetail (id, type) {
 debugger
 this.$router.push({
   path: '/NewsListBM/Detail/',
   query: {
     id,
     type
   }
 });
},
//获取行业动态
getNewListSc () {
 let data = {
   page: 1,
   limit: 3,
   categoryId: '1612993825084301313',
   orderField: "release_time",
   order: "desc"
 }
 this.$http.get(`/attract_investment/front/show/newsList`, {
   params: {
     ...data
   }
 }).then(({
   data: res
 }) => {
   this.hyNewFirst = res.list[0].id
   this.hyNewList = res.list
 })
},
//获取邦芒新闻
getNewListBm () {
 let data = {
   page: 1,
   limit: 3,
   categoryId: '1612993917329629186',
   orderField: "release_time",
   order: "desc"
 }
 this.$http.get(`/attract_investment/front/show/newsList`, {
   params: {
     ...data
   }
 }).then(({
   data: res
 }) => {
   this.bmNewFirst = res.list[0].id
   this.bmNewList = res.list
 })
},


//获取省
getSList () {
 this.$http.get(`/attract_investment/front/show/getProvinceList`).then(({
   data: res
 }) => {
   this.options.sList = res
 })
},
//获取市
getQList () {
 this.$http.get(`/attract_investment/front/show/getPlacetbPage`, {
   params: {
     parentid: this.dataForm.sCode,
     deep: 2
   }
 }).then(({
   data: res
 }) => {
   this.options.qList = res.list
 })
},
//跳转contact
goToContact () {
 this.$router.push({
   name: 'contact',
   params: {
     dataForm: this.dataForm,
     type: 2
   }
 });
},
// 获取新闻
getNewList () {
 this.$http.get(`/attract_investment/front/show/newsList`)
},
},
}
</script>
<style lang="scss" scoped>
.about_top_video {
  video {
    width: 596px;
  }
}

.bmrl_body {
  margin-top: 30px;
}

.form_title {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 10px;

  img {
    width: 32px;
    height: 36px;
    margin-right: 10px;
  }

  h3 {
    color: #ab1621;
    font-size: 24px;
    margin: 0;

    span {
      font-size: 16px;
      margin-left: 10px;
    }
  }
}

.body_form {
  background: #ededed;
  box-sizing: border-box;
  padding: 35px 52px;
  margin-top: 80px;
}

.honor {
  padding: 75px 0 90px;
}

.honor_title h3 {
  font-size: 36px;
  color: #d1b376;
  margin: 0;
  margin-bottom: 15px;
}
.honor_title img {
  margin: 0 auto;
}

.honor_body {
  background: #f1f1f1;
  padding: 60px;
  margin-top: 35px;
}


.new_box {
  padding: 90px 0;
}

.banner {
  width: 100%;
}

.banner img {
  width: 100%;
}

.about {
  padding: 110px 0 85px;
}

.new_hot_item_title {
  width: 60%;
  overflow: hidden;
  height: 25px;
  overflow: hidden;
}

.newlist_box_left {
  width: 49%;
}

.newlist_box_right {
  width: 49%;
}

.newItem {
  width: 100% !important;
}

.new_hot_item {
  width: 49%;
}

.new_hot_item img {
  width: 100%;
  display: block;
  height: 400px;
}

.new_hot_title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .54);
  width: 100%;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.new_hot_title p {
  margin: 0;
  font-size: 18px;
  text-align: left;
}

.new_item_text {
  text-align: left;
  overflow: hidden;
  width: 60%;
  height: 25px;
}

.new_item_time {
  text-align: right;
  overflow: hidden;
}

.new_hot_item {
  position: relative;
}

.aout_top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.new_list_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 35px 0;
}

.about_top_text {
  width: 46%;
}

.about_title_box h3 {
  font-size: 36px;
  color: #d4b391;
  margin: 0;
}

.about_title_box h4 {
  color: #777777;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.about_title_box h4::after {
  content: '';
  width: 100px;
  height: 5px;
  background: #d4b391;
  position: absolute;
  left: -3px;
  bottom: 0px;
}

.about_title_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.about_text-wz {
  text-align: left;
  font-size: 16px;
  color: #666666;
  line-height: 26px;

}

.new_item {
  width: 49%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px dashed #cccccc;
  color: #666666;
}

.about_text-wz p {
  margin: 10px 0;
}

.about_bottom_item {
  border: 1px dashed #af9575;
  padding: 15px;
  width: 15%;
  position: relative;
}

.about_bottom_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.about_bottom_item h4 {
  margin: 0;
  font-size: 16px;
  color: #af9575;
  text-align: left;
}

.big_text {
  color: #b21e29;
  font-size: 35px;
  font-weight: bold;
  font-style: oblique;
  margin: 35px 0;
}

.big_text span {
  font-size: 14px;
  font-style: initial;
  font-weight: inherit;
}

.about_botoom {
  margin-top: 108px;
}

.sanjiao {
  position: absolute;
  left: 50%;
  margin-left: -22px;
  bottom: 0;
}

.serve_top {
  width: 75px;
  height: 75px;
  border-radius: 75px;
  background: #fff;
  margin: 0 auto;
  line-height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.serve_tab p {
  font-size: 16px;
  color: #656565;
  margin: 0;
}

.serve {
  background: #ededed;
  padding-bottom: 105px;
}

.serve_title h3 {
  font-size: 36px;
  color: #d4b391;
  margin: 0;
  margin-bottom: 15px;
}

.serve_title p {
  font-size: 18px;
  color: #777777;
  margin: 0;
}

.serve_title {
  padding: 45px 0;
}

.henxian {
  width: 45px;
  height: 5px;
  background: #d4b391;
  margin: 0 auto;
  margin-top: 14px;
}

.serve_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 45px;
}

.serve_img {
  width: 49%;

  img {
    width: 100%;
  }
}

.serve_text {
  width: 49%;
  text-align: left;
  font-size: 16px;
  color: #656565;
  line-height: 36px;
  box-sizing: border-box;
  padding-right: 80px;
}

.serve_top_picbox {
  width: 36px;
  height: 36px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.serve_top_picbox img {
  width: 100%;
  display: block;
  position: absolute;
  filter: drop-shadow(37px 0 0 #d1ad87);
}

.serve_more:hover {
  .img_off {
    z-index: 0;
  }

  .img_on {
    z-index: 1;
  }
}

.img_off {
  z-index: 1;
}

.img_on {
  z-index: 0;
}

.serve_more {
  cursor: pointer;
  position: relative;
  width: 100px;
  height: 35px;
  text-align: center;

  img {
    position: absolute;
    left: 0;
    top: 0;
  }

  p {
    position: absolute;
    z-index: 10;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #d4b391;
  }
}

.serve_more:hover p {
  color: #fff;
}

.ys_title h3 {
  font-size: 36px;
  color: #d4b391;
  margin: 0;
}

.ys_title p {
  font-size: 16px;
  color: #666666;
}
.ys_title img {
  margin: 0 auto;
}

.advantage {
  padding-top: 100px;
  padding-bottom: 135px;
}

.ys_henxian {
  width: 20px;
  height: 4px;
  background: #e50011;
}

.ys_item {
  background: #ededed;
  width: 24%;
  box-sizing: border-box;
  padding: 35px 25px;
}

.ys_item:hover {
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);

  .ys_img_one {
    z-index: 0;
  }

  .ys_img_two {
    z-index: 1;
  }
}

.ys_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 65px;
}

.ys_title_sm {
  font-size: 16px;
  color: #c2a180;
  text-align: left;
}

.ys_img {
  margin-top: 6px;
  margin-bottom: 20px;
  position: relative;
  width: 238px;
  height: 90px;

  img {
    position: absolute;
    left: 75px;
  }

  .ys_img_one {
    z-index: 1;
  }

  .ys_img_two {
    z-index: 0;
  }
}


.ys_text {
  font-size: 16px;
  color: #454545;
}

.ys_henxian {
  margin: 0 auto;
  margin-top: 16px;
}

.bmrl_title h3 {
  font-size: 36px;
  color: #d4b391;
}
.bmrl_title img {
  margin: 0 auto;
}
.bmrl_title p {
  font-size: 16px;
  color: #666666;
}

.henfu {
  height: 231px;
  background: url("../../src/assets/img/henfu_bg.png") center center no-repeat;
  font-size: 60px;
  color: #fff;

}

.henfu h3 {
  font-weight: 400;
  margin: 0;
  padding: 65px 0;
}

.bmrl_box {
  padding: 75px 0;
}

.bmrl_title h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 36px;
}

.bmrl_title p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
}

.bgal {
  background: url(../../src/assets/img/anli_bg.png) center center no-repeat;
  padding: 75px 0 125px;
  background-size: 100% 100%;
}

.bgal_title h3 {
  font-size: 36px;
  color: #666666;
  margin: 0;
  margin-bottom: 20px;
}

.bgal_title h4 {
  font-size: 36px;
  color: #333333;
  margin: 0;
  margin-bottom: 20px;
}

.bgal_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 50px;
}

.bgal_item {
  width: 24%;
  background: #fff;
  padding-bottom: 35px;
}

.bgal_item_text {
  text-align: left;
}

.bgal_item_text {
  padding: 30px 18px;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  height: 130px;
}

.bgal_item_foot {
  text-align: right;
  font-size: 14px;
  color: #b82323;
  padding: 0 18px;
}

.bgal_item_img img {
  width: 100%;
}



.new_title h3 {
  font-size: 35px;
  color: #d4b391;
  margin: 0;
  margin-bottom: 28px;
}

.new_title img {
  margin: 0 auto;
}

.new_hot_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}
:deep(.swiper-pagination) {
  .swiper-pagination-bullet {
    transition: width 0.3s ease-in-out;
    &.swiper-pagination-bullet-active {
      width: 25px;
      background: #fff;
      border-radius: 5px;
    }
  }

}
</style>
<style lang="scss">

.logo_box {
  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-tabs__nav {
    margin: 0 auto;
    text-align: center;
    clear: both;
    float: initial;
  }

  .el-tabs__active-bar {
    display: none;
  }

  .el-tabs__item {
    font-size: 14px;
    color: #666666;
  }

  .el-tabs__item.is-active {
    color: #cc9966;
  }

  .el-tabs__item:hover {
    color: #cc9966;
  }
}


.serve_body {
  .el-tabs__item {
    height: auto !important;
    width: 170px;
  }

  .el-tabs__item:nth-child(2) {
    padding-left: 20px !important;
  }

  .el-tabs__item:last-child {
    padding-right: 20px !important;
  }

  .el-tabs__item:nth-child(6),
  .el-tabs__item:nth-child(7),
  .el-tabs__item:nth-child(8) {
    width: 160px;
  }

  .el-tabs__nav {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .el-tabs__active-bar {
    display: none;
  }

  .el-tabs__nav-wrap::after {
    background-color: #c3c3c3 ! important;
  }

  .el-tabs__nav-scroll {
    padding-bottom: 13px;
  }

  .is-active {
    p {
      color: #d1ad87;
    }

    .serve_top_picbox img {
      left: -37px;
    }
  }
}

.body_map>img {
  width: 100%;
}
</style>
