<template>
    <div class="industry">
        <heads></heads>
        <div class="banner">
            <img src="@/assets/img/cyfz_banner.jpg">
        </div>


        <div class="industry_body" id="cyfzzxNav">
            <div class="all_box">
                <!-- Swiper -->
                <div class="bannerBox">
                    <div class="swiper-container gallery-top">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(item,index) of swiperList" :key="index">
                                <img :src="item.logo" class="logo">
                                <span>{{item.h3}}</span>
                            </div>
                        </div>
                        <div class="diandiandian">...</div>
                    </div>
                    <div class="swiper-button-next swiper-button-white">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <div class="swiper-button-prev swiper-button-white">
                        <i class="el-icon-arrow-left"></i>
                    </div>
                    <div class="swiper-container gallery-thumbs">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide swiper-bottom" v-for="(item,index) of swiperList" :key="index">
                                <div class="swiper_box" v-if="item.h3 != '有余坊'">
                                    <div class="swiper_bg">
                                        <h3>{{item.h3}}</h3>
                                        <p v-html="item.p"></p>
                                        <!-- 产业园区 有余坊 不展示 -->
                                        <div class="swiper_more" v-if="item.h3 !='产业园区'&&item.h3 !='有余坊' &&item.h3 !='劳博士' ">
                                            <a :href="item.href" target="_blank">查看详情+</a>
                                        </div>
                                        <!-- 有余坊组件 -->
                                        <div v-if="item.h3 == '有余坊'" class="chenyiBox">
                                            <div class="swiper_more chenyi_more" @mouseover="aaa()" @mouseout="bbb()">
                                                查看详情+
                                            </div>
                                            <div class="swiper_more_erweima" v-if="erweimaHidden">
                                                <img src="@/assets/img/erweima.png">
                                            </div>
                                        </div>

                                        
                                    </div>
                                    
                                    <div v-if="item.h3 == '聘也科技'" class="swiper_pic" style="top: 20%; left: 66%;">
                                        <img :src="item.img" style="width: 310px;">
                                    </div>
                                    <div v-else-if="item.h3 == '邦芒招商'" class="swiper_pic">
                                        <img :src="item.img" style="width: 410px; border-radius: 11px;">
                                    </div>
                                    <div v-else-if="item.h3 == '劳博士'" class="swiper_pic">
                                        <img :src="item.img" style="width: 410px; ">
                                    </div>
                                    <div v-else class="swiper_pic">
                                        <img :src="item.img">
                                    </div>
                                </div>
                                <!-- 有余坊 -->
                                <div class="swiper_box" v-else>
                                    <div class="swiper_bg2">
                                        <h3>{{item.h3}}</h3>
                                        <p v-html="item.p"></p>
                                    </div>
                                    <div class="swiper_pic_publicImg">
                                        <img :src="item.publicImg">
                                        <h3>有余坊公众号</h3>
                                    </div>
                                    <div class="swiper_pic2">
                                        <img :src="item.img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <foots @navChange="typeNav"></foots>
    </div>
</template>
<script>
import $ from "jquery"
    import Swiper from "swiper"
    import heads from '@/components/head/index.vue'
    import foots from '@/components/foot/index.vue'
    export default {
        name: 'industry',
        data() {
            return {
                erweimaHidden:false,
                activeName: 'first',
                footType: '',
                swiperList: this.$config.developerCompanys
            }
        },
        mounted() {
            if (this.$route.params.type) {
                this.footType = this.$route.params.type
                document.getElementById("cyfzzxNav").scrollIntoView();
            }


            this.galleryThumbsLunbo();
            this.galleryTopLunbo();

            this.bdsjjb()
          setTimeout(() => {
            this.typeNav(this.footType)
          } , 500)

        },
        methods: {
         
            aaa(){
              this.erweimaHidden = true
            },
            bbb(){
                this.erweimaHidden = false
            },
            bdsjjb() {
                let _this = this
                let a = document.getElementsByClassName('gallery-top')
                let b = a[0].getElementsByClassName('swiper-slide')
                for (let i = 0; i < b.length; i++) {
                    b[i].onclick = function (ev, ) {
                        _this.clickSwiperSide(ev, i, b)
                    }
                }

            },

            clickSwiperSide(ev, index, doc) {
                let swiperTitle = document.getElementsByClassName('swiper-wrapper')[0]
                let swiperBody = document.getElementsByClassName('swiper-wrapper')[1]
                $(".gallery-top .swiper-wrapper .swiper-slide.swiper-slide-active").removeClass("swiper-slide-active")
                $(ev.target).closest(".swiper-slide").addClass("swiper-slide-active")
                swiperTitle.style.transitionDuration = '300ms'
                swiperBody.style.transitionDuration = '300ms'

                const step = 171.43
                function setTran3d(index, body) {
                    swiperTitle.style.transform = `translate3d(${-step * index}px, 0px, 0px)`
                    swiperBody.style.transform = `translate3d(${body}px, 0px, 0px)`
                }
                // 邦芒招商
                if(index == 6|| index == 12) {
                    return setTran3d(index, -6000)
                }
                // 聘也科技
                if (index == 7 || index == 13) {
                    return setTran3d(index, -14400)
                }
                // 人事宝科技
                if( index == 8 || index == 14) {
                    return setTran3d(index, -8400)
                }
                // 有余方
                if (index == 9 || index == 15) {
                    return setTran3d(index, -9600)
                }
                // 福能会计
                if (index == 10 || index== 16) {
                    return setTran3d(index, -10800)
                }
                // 劳博士
                if(index == 11 || index == 17) {
                    return setTran3d(index ,-12000)
                }
            },

            galleryThumbsLunbo() {
                this.galleryThumbs = new Swiper('.gallery-thumbs', {
                    spaceBetween: 0, //在slide之间设置距离（单位px）
                    slidesPerView: 1, //设置slider容器能够同时显示的slides数量
                    loop: true, //设置为true 则开启loop模式
                    freeMode: true, //默认为false，普通模式：slide滑动时只滑动一格
                    loopedSlides: 5, //一般设置大于可视slide个数2个即可
                    watchSlidesVisibility: true, //开启watchSlidesVisibility选项前需要先开启watchSlidesProgress
                    watchSlidesProgress: true, //开启这个参数来计算每个slide的progress(进度、进程)
                });
            },
            galleryTopLunbo() {
                this.galleryTop = new Swiper('.gallery-top', {
                    spaceBetween: 0,
                    slidesPerView: 7,
                    loop: true,
                    loopedSlides: 6,
                    // 左右按钮
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    thumbs: { //thumbs组件专门用于制作带缩略图的swiper
                        swiper: this.galleryThumbs
                    },
                });
            },




            handleClick(tab, event) {
                console.log(tab, event);
            },
            typeNav(type) {
                let a = document.getElementsByClassName('gallery-top')
                let doc = a[0].getElementsByClassName('swiper-slide')
                let swiperTitle = document.getElementsByClassName('swiper-wrapper')[0]
                let swiperBody = document.getElementsByClassName('swiper-wrapper')[1]
                if (type == 1) {
                    //有余坊
                }
                if (type == 2) {
                    //产业园区
                    for(let i = 0; i<doc.length;i++){
                        if(i == 12){
                            doc[12].className = 'swiper-slide swiper-slide-active'
                        }else{
                            doc[i].className = 'swiper-slide'
                        }
                    }
                    swiperTitle.style.transform = 'translate3d(-2057.14px, 0px, 0px)'
                    swiperBody.style.transform = 'translate3d(-12000px, 0px, 0px)'
                }
                $(".gallery-top .swiper-wrapper .swiper-slide.swiper-slide-active").removeClass("swiper-slide-active")
                $(`.swiper-wrapper .swiper-slide:eq(${type==1?type+2:type})`).closest(".swiper-slide").addClass("swiper-slide-active")

                const step = 171.43
                function setTran3d(index, body) {
                    swiperTitle.style.transform = `translate3d(${-step * index}px, 0px, 0px)`
                    swiperBody.style.transform = `translate3d(${body}px, 0px, 0px)`
                }
                // // 邦芒招商
                // if(index == 1|| index == 12) {
                //     return setTran3d(index, -6000)
                // }
                // 聘也科技
                const index =type
                if (index == 7 || index == 13) {
                    return setTran3d(index, -14400)
                }
                // 人事宝科技
                if( index == 8 || index == 14) {
                    return setTran3d(index, -8400)
                }
                // 有余方
                if (index == 1 || index == 15) {
                    return setTran3d(index, -9600)
                }
                // 福能会计
                if (index == 10 || index== 16) {
                    return setTran3d(index, -10800)
                }
                // 劳博士
                if(index == 5 || index == 17) {
                    return setTran3d(index ,-12000)
                }
            }
        },
        components: {
            heads,
            foots
        }
    }
</script>
<style lang="scss" scoped>
    .diandiandian {
        position: absolute;
        right: 0;
        color: #5c5c5c;
        top: 13px;
        font-size: 20px;
    }

    .bannerBox {
        position: relative;
    }

    .industry_body {
        padding: 150px 0 160px;
    }

    .banner {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .swiper_box {
        position: relative;
        margin-top: 87px;
    }

    .swiper_pic {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -145px;
    }

    .swiper_pic2 {
        position: absolute;
        right: 0;
        top: -6px;
    }

    .swiper_pic_publicImg {
        position: absolute;
        right: 315px;
        top: 122px;
        h3 {
            font-size: 24px;
            line-height: 32px;
            color: #333333;
            margin: 0;
            margin-bottom: 30px;
        }
    }

    .logo {
        height: 34px;
        width: auto;
        margin-bottom: -5px;
        display: none;
        margin-right: 10px;
    }

    .swiper_bg {
        box-sizing: border-box;
        background: #f4f4f4;
        padding: 77px 272px 77px 64px;
        text-align: left;
        width: 85%;
        border-radius: 20px;

        h3 {
            font-size: 36px;
            color: #010101;
            margin: 0;
            margin-bottom: 30px;
        }

        p {
            font-size: 16px;
            color: #454545;
            margin-bottom: 30px;
            line-height: 26px;
        }
    }
    .swiper_bg2 {
        box-sizing: border-box;
        background: #f4f4f4;
        padding: 77px 272px 77px 64px;
        text-align: left;
        width: 990px;
        height: 440px;
        border-radius: 20px;

        h3 {
            font-size: 36px;
            color: #010101;
            margin: 0;
            margin-bottom: 30px;
        }

        p {
            font-size: 16px;
            color: #333333;
            margin-bottom: 30px;
            line-height: 32px;
            width: 578px;
            font-weight: 400;
            font-family: "Microsoft YaHei";
        }
    }

    .swiper_more {
        width: 150px;
        height: 51px;
        background: #c81422;
        color: #fff;
        line-height: 51px;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;

        a {
            color: #fff;
            text-decoration: none;
            display: block;
        }
    }
</style>
<style lang="scss">
    .industry_body {
        .swiper-button-prev {
            background: url(../../assets/img/cyfzzx_left.png) center center no-repeat;
            background-size: 100% 100%;
            width: 12px;
            height: 20px;
            top: 40px;
            left: -20px;

            i {
                display: none;
            }
        }

        .swiper-button-prev:after {
            content: '';
        }

        .swiper-button-next {
            background: url(../../assets/img/cyfzzx_right.png) center center no-repeat;
            background-size: 100% 100%;
            width: 12px;
            height: 20px;
            top: 40px;
            right: -20px;

            i {
                display: none;
            }
        }

        .swiper-button-next:after {
            content: '';
        }

        .gallery-top {
            border-bottom: 1px solid #b2b2b2;
            cursor: grab;
        }

        .swiper-slide {
            font-size: 18px;
            color: #a7a7a7;
            line-height: 60px;
        }

        .chenyiBox {
            position: relative;
            .swiper_more_erweima {
                position: absolute;
                width: 120px;
                top: -10px;
                left: 160px;
                img {
                    width: 100%;
                }
            }
        }
        .gallery-top {
            position: relative;

            .swiper-slide-active:after {
                content: '';
                width: 100%;
                height: 5px;
                background: #ab1621;
                border-radius: 10px;
                position: absolute;
                left: 0;
                bottom: -3px;

            }

            .swiper-slide-active {
                font-size: 25px;
                color: #ab1621;
                border-bottom: 2px solid #ab1621;

                img {
                    display: inline-block;
                }
            }
        }


       
    }
</style>